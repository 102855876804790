import React from "react"

/**
 * @title StylesParser Component
 * @param {String} className CSS classes
 * @param {String} text
 * @param {String} tag A scelta fra ["#b","#c"]
 * @returns {React.Component} Fragment contenente il testo rielaborato
 * @description Componente che ritorna un Fragment con all'interno il testo ricevuto in input con lo stile
 * indicato dal tag. Al momento il tag "#b" indica il grasseto, mentre il "#c" il corsivo. Il testo "text" 
 * deve essere composto in modo tale che le sue parti, che devono avere gli stili indicati (anche dalle
 * classi in input), siano comprese tra i tag configurati.
 * Es: Ciao #bMondo#b post esecuzione della funzione mostrerà la parola Mondo in grasseto e con le classi
 * CSS ricevute in input.
 */

const StylesParser = ({tag,text,className}) => {

    const BOLD = "#b"
    const ITALIC = "#c"
    const tag_const= tag
    let array_splitted = []

    if(text != null && text.indexOf(tag_const) > 0) {
        switch (tag_const) {
            case BOLD :
                array_splitted = text.split(BOLD)
                break
            case ITALIC :
                array_splitted = text.split(ITALIC)
                break
            default:
                array_splitted.push(text);
                break
        } 
    }else array_splitted.push(text);

    const checkTag = () => {
        switch (tag_const) {
            case BOLD :
                return "font-bold "+className
            case ITALIC :
                return "italic"
            default:
                return "";
        }
    }

    const Element =
    <>
        {array_splitted.map((string, index) =>{
            if(index % 2 !== 0)
                return <span key={index} className={checkTag()}>{string}</span>
            else
                return string
        })}
    </>
    return Element
}

export default StylesParser