import React from "react"

import costanti from "../properties/costanti"
import Svg from "../components/ui/svg"
import Title from "../components/ui/title"
import StylesParser from "../components/ui/styles-parser"
import OpenSection from "../components/layouts/open-section"
import Seo from "../components/seo"

/**
 * @title AboutPage
 * @returns {React.Component} Pagina HTML figlia del componente Layout
 * @description Pagina Html dedicata alla descrizione dell'azienda..
 * E' presente anche il tag SEO, componente ad uso esclusivo per dei componenti pagina.
 */

export default function about(){

    const id_about = "L'azienda"
    const id_values = "I Valori"
    const top_padding = " py-10 lg:py-20 "
    const section_lateral_padding = " px-9 lg:px-10 "
    const classiParser = "text-secondary-600 duration-300 group-hover:text-primary-500"
    const open_title = "Il riferimento territoriale di fiducia"
    const open_subtitle = "Il nostro obiettivo è farci conoscere e stabilire un rapporto che duri nel tempo, basato sulla fiducia reciproca"
    const end_page = "Saremo il tuo partner di riferimento per #bconsigliarti#b e #baiutarti#b in un settore in continua evoluzione, con #bsoluzioni innovative#b e su misura per te."

    return(
        <>
            <Seo title="Chi siamo" description="Il riferimento territoriale di fiducia. Il nostro obiettivo è farci conoscere e stabilire un rapporto che duri nel tempo, basato sulla fiducia reciproca"/>

            <div className={"w-full h-auto flex flex-col justify-center items-center text-justify text-xl"}>
                <div className={`group w-full flex flex-col items-center justify-center 
                text-secondary-600 dark:text-secondary-100 `+ costanti.theme.color.img.inverted}>
                    <OpenSection
                    leftText={open_title}
                    leftSubChild={
                        <p data-sal="slide-down" data-sal-duration="1000"
                        className="text-white text-base md:text-xl font-bold pt-5 portrait:pb-10">
                            {open_subtitle}
                        </p>
                    }
                    rightChild={
                        <Svg.BioExp className="group-hover:scale-105 group-hover:-hue-rotate-30 duration-500"/>
                    }
                    darkbg
                    noLink
                    />
                </div>

                <section id={id_about} title={id_about} className={`group flex flex-col lg:flex-row justify-center
                items-center ` + section_lateral_padding + top_padding}>
                    
                    <Svg.ShowSupport
                    className={`hidden lg:block pt-10 lg:pt-0 w-4/5 lg:w-1/3 
                    group-hover:-hue-rotate-30 duration-300 group-hover:scale-105 `}/>
                    
                    <div className={"w-full lg:w-2/3 lg:pl-10 my-auto" }>
 
                        <Title text="- AZIENDA -" mt="mt-20" size="xl" bg="bg-secondary-500"
                        classes="group-hover:bg-emerald-500 " center skew />
 
                        <div className="pt-5 group" >
                            <p data-sal="zoom-in" data-sal-duration="1000">
                                <StylesParser 
                                tag="#b" className={classiParser} text=" #bTechfriuli#b è un'azienda operante nel settore
                                    ICT nata nel 2010 che, sulle basi di una #besperienza
                                    consolidata#b nella progettazione e produzione di software e nella erogazione di servizi, propone 
                                    soluzioni realizzate con tecnologie innovative e servizi a misura del cliente.
                                    Progettiamo e realizziamo software multipiattaforma ed siamo specializzati in tecnologie OpenSource."/>
                            </p>
                            {/* <p data-sal="zoom-in" data-sal-duration="1000">
                            <StylesParser tag="#b" className={classiParser} text="
                                Techfriuli commercializza un sistema congressuale di sua produzione customizzato sulle
                                specifiche esigenze delle agenzie congressuali operanti nel settore degli eventi e delle fiere.
                                Il servizio permette di rendere semplice l’interazione tra gli organizzatori e i partecipanti e
                                fornisce preziose reportistiche in tempo reale sull’andamento dell’evento stesso."/>
                            </p>
                            
                            <p data-sal="zoom-in" data-sal-duration="1000">
                            <StylesParser tag="#b" className={classiParser} text=" 
                                Techfriuli realizza portali WEB ad elevato grado di personalizzazione sulle specifiche esigenze
                                del clienti, fornisce consulenza su sistemi ed apparecchiature ICT, assistenza sistemistica e
                                manutenzione hardware e reti fino alla completa realizzazione di strutture informatiche sia per
                                il professionista che per l’impresa. Si occupa di fornire proposte per la sicurezza attiva e passiva
                                e di controllo sui dati e sugli accessi."/>
                            </p> */}
                            <br />
                            <p data-sal="zoom-in" data-sal-duration="1000">
                                <StylesParser tag="#b" className={classiParser} text=" 
                                    Techfriuli è anche #bpartner#b, #bqualificato e segnalato#b sul sito dell’Ordine degli Avvocati di Udine per la sua
                                    competenza e preparazione, in grado di affiancare anche il professionista con particolari esigenze
                                    di riservatezza e alta professionalità."  />
                            </p>
                        </div>
                        <Svg.ShowSupport className={"lg:hidden pt-10 group-hover:-hue-rotate-30 duration-300 md:group-hover:scale-105"}/>
                    </div>
                </section>

                <section id={id_values} title={id_values}
                className={`group flex flex-row items-center justify-center bg-cover
                bg-[url('/images/home-bg-hover.png')] mt-24 pb-28 ` + section_lateral_padding}>                          
                    
                    <div className={"flex flex-col w-full pb-20 text-white" }>
                        
                        <Title text="- Valori -" mt="-mt-16" color="text-white"
                        bg="bg-primary-500 group-hover:bg-emerald-500"
                        size="xl" classes={`md:group-hover:scale-110 border-1
                        border-secondary-800 shadow-2xl rounded-2xl `} center />
                        
                        <div className="flex flex-col ">

                            <div className="flex flex-row w-full">
                                <Svg.Team2 className={`lg:w-1/3 hidden lg:block md:group-hover:scale-105 
                                group-hover:-hue-rotate-30 duration-500 lg:pr-10`} />
 
                                <div data-sal="slide-left" data-sal-duration="600"
                                className="w-full lg:p-4 hover:scale-105 lg:shadow-2xl duration-500">
                                
                                    <p className="py-6 font-bold text-3xl text-white">Visione:</p>
                                    <StylesParser tag="#b" className="text-primary-500" text="
                                    Essere il partner di riferimento delle piccole e medie imprese per la parte ICT valorizzando il loro processo produttivo."/>
                                    <br /><br />
                                    <StylesParser tag="#b" className="text-primary-500" text="
                                    Sostenere i processi interni all’azienda fornendo il giusto supporto per migliorare la produttività e l’efficienza dei servizi."/>
                                    <br /><br />
                                    <StylesParser tag="#b" className="text-primary-500" text="
                                    Provvedere alla corretta e completa evoluzione dei servizi informatici a sostegno del business e della semplicità operativa." />
                                    
                                </div>
                            </div>

                            <div className="w-full lg:p-4 hover:scale-105 lg:shadow-2xl duration-500"
                            data-sal="zoom-in" data-sal-duration="1000">
                                <p className="py-6 font-bold text-3xl text-white">Missione:</p>
                                <p>Ci prensentiamo al mercato non come fornitori ma come Partner ponendoci tre obiettivi:</p>
                                <br />
                                <StylesParser tag="#b" className="text-primary-500" text="
                                Fornire le migliori soluzioni software e hardware, considerando le esigenze del Partner e il giusto equilibrio fra qualità e costo, garantendo un servizio competente e puntuale."/>
                                <br /><br />
                                <StylesParser tag="#b" className="text-primary-500" text="
                                Assistere il Partner affiancandolo in tutte le tappe della sua crescita tecnologica, dallo sviluppo dell’idea e definizione del progetto alla sua realizzazione, nonchè alla formazione, mantenimento e future evoluzioni."/>
                                <br /><br />
                                <StylesParser tag="#b" className="text-primary-500" text="
                                Dare a tutti i Partner l’assistenza necessaria a migliorare l’efficienza delle proprie strutture operative attraverso momenti di confronto per l’analisi dei processi aziendali.
                                " />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="w-full flex items-center justify-center relative mt-20">

                    <Svg.Team1 className="w-4/5 md:w-2/3 lg:1/2 hover:-hue-rotate-30 duration-500"/>
                    
                    <div data-sal="slide-up" data-sal-duration="600" 
                    className="absolute w-3/4 md:w-2/3 bg-white px-6 py-9 md:px-10 md:py-16 rounded-3xl
                    text-center text-lg sm:text-xl md:text-2xl lg:text-4xl text-secondary-600
                    font-bold shadow-2xl top-0 -mt-56 border border-secondary-500">
                        <StylesParser tag="#b" className="text-primary-500"
                        text={end_page} />
                    </div>

                </div>
            </div>

        </>
    )
}