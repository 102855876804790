import React from "react"

/**
 * @title First Section Layout
 * @param {*} leftText
 * @param {*} leftSubChild
 * @param {*} rightChild
 * @param {Boolean} darkbg
 * @returns {React.Component} Div element
 * @description Layout per la prima sezione di tutte le pagine ad esclusione dell'home e 404. I parametri, come 
 * indicano i rispettivi nomi, sono gli elementi che verranno inseriti negli spazi indicati.
 */

function Section({leftText, leftSubChild, rightChild, darkbg}){
    return(
        <div className={`flex flex-col landscape:flex-row lg:flex-row w-4/5 justify-center lg:justify-between text-left
        h-screen md:h-[85vh] max-h-screen items-center`}>
            <div className="flex flex-col w-full lg:w-2/3 ">
                <p data-sal="slide-right" data-sal-duration="900"
                className={`text-3xl md:text-5xl xxl:text-6xl
                font-sans font-bold leading-tight uppercase group-hover:scale-110 ` +
                (darkbg ? "text-white" : "text-secondary-600")}>
                    {leftText}
                </p>
                {Boolean(leftSubChild) && leftSubChild }
            </div>
            <div data-sal="slide-left" data-sal-duration="900"
            className={`flex justify-center items-center w-full md:w-1/2 lg:w-1/3 portrait:max-h-[30vh]`}>
                {rightChild}
            </div>
        </div>
    )
}

export default Section