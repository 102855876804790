import * as React from "react"

import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


/**
 * @title SEO Component
 * @param {String}  description description meta tag
 * @param {String}  lang tag lingua del sito
 * @param {Object}  meta Object che vengono concatenati come meta tag a quelli di default. Pattern: {name: '', content: Object}
 * @param {String}  title Titolo della pagina. In assenza viene impostato quello di default
 * @param {String}  metaTitle metatag SEO, in assenza viene usato il parametro "title"
 * @returns Tramite il plugin Helmet vengono impostati i metatag del sito.
 * @description Componente che sfrutta il plugin Helmet per impostare i metatag delle pagine. Riceve in input
 * la descrizione, la lingua, eventuali, meta-data extra da concatenare a quelli di default e il titolo della pagina
 * che seguirà il template $Titolo - Techfriuli. I dati di default li recupera tramite Static Query graphQL dal gatsby-config.js
 */

function Seo({ description, lang, meta, title, metaTitle }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  // const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    titleTemplate={ title === 'Home' ? 'TechFriuli' : `%s` }
    // titleTemplate={ title === 'Home' ? 'TechFriuli' : (defaultTitle ? `%s - ${defaultTitle}` : `%s` ) }
    meta={[
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: metaTitle || title,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: site.siteMetadata?.author || ``,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
    ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `it`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  metaTitle: PropTypes.string,
  description: PropTypes.string,
}

export default Seo
