import React from "react"


/**
 * @title Title Component
 * @param {String} color Tailwind CSS class 
 * @param {String} classes
 * @param {Boolean} sans
 * @param {Boolean} skew
 * @param {String} bg Tailwind CSS class
 * @param {String} mt Tailwind CSS class
 * @param {Boolean} center
 * @param {String} text
 * @param {String} subtitleText
 * @param {String} size A scelta fra ["s","m","l","xl","xxl"] 
 * @returns {React.Component} Div Html Element
 * @description Componente che renderizzato ritorna un Div con il titolo con lo stile di default o con quelli ricevuti in input;
 * Il componente è una tra le prime prove di integrazione con il framework Tailwind. Andrebbe sicuramente modificato in futuro
 * rimuovendo alcuni parametri in ingresso in favore delle classi tailwind, così da ottimizzarne la struttura e renderla meno complessa
 * di quanto non serva.
 */

const Title = ({color,classes,sans,skew,bg,mt,center,text,subtitleText,size}) => {
    // 'text-primary-500'
    const text_color = Boolean(color) ? color : 'text-white'
    const text_class = Boolean(classes) ? classes : ''
    const text_style = Boolean(sans) ? 'font-sans' : ''
    const text_skew = Boolean(skew) ?  'group-hover:-skew-x-6 skew-x-6 group-hover:shadow-xl' : ''
    const bg_color = Boolean(bg) ? bg : 'bg-secondary-600'
    const text_small_size = "text-3xl "
    let text_size
    switch (size) {
        case("s"):
            text_size = "text-xl"
            break
        case("m"):
            text_size = text_small_size + "md:text-3xl"
            break
        case("l"):
            text_size = text_small_size + "md:text-5xl"
            break
        case("xl"):
            text_size = text_small_size + "md:text-6xl"
            break
        case("xxl"):
            text_size = text_small_size + "md:text-7xl"
            break
        default:
            text_size = text_small_size + "md:text-3xl"
            break
    }
    const input_class = " " + bg_color + " " + text_size + " " + text_color + " " + text_class + " " + text_style + " " + text_skew

    return(
        <div data-sal="flip-up" data-sal-duration="800"
        className={(Boolean(mt) ? mt:"") + " flex flex-col mb-10 " + (Boolean(center) ? "justify-center items-center":"")}>
            <div className={"w-fit uppercase font-bold duration-300 px-4 py-8 " + input_class}>
                {text}
            </div>
            {subtitleText && <p className="text-2xl mt-4 font-bold uppercase text-secondary-500">― {subtitleText} —</p>}
        </div>
    )
}

export default Title